<script>
import { mapActions } from 'vuex';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
    props: {
        user: {
            type: Object
        },
        canSave: {
            type: Boolean
        },
    },

    data() {
        return {
            ratingDiv: [
                {
                    text: "Prebačaj rezultata u trenutnoj ulozi",
                    color: "success",
                },
                {
                    text: "Apsolutno ostvarenje rezultata u trenutnoj ulozi",
                    color: "info",
                },
                {
                    text: "Rezultati dobri, ali neophodan razvoj za vršenje trenutne uloge",
                    color: "warning",
                },
                {
                    text: "Rezultati loši, potrebno unapređenje za vršenje trenutne uloge ili prelazak na drugo radno mesto",
                    color: "danger",
                }
            ],
            clicked: null,
            id: "",
            employee: "",
            job: "",
            category: "",
            period: "",
            date: "",
            capability: "",
            capabilityActions: "",
            capabilityMeasures: "",
            area: "",
            areaActions: "",
            areaMeasures: "",
            finalRating: "",
            comment: "",
            boss: "",
            hr: "",
            companyList: [],
            usersList: [],
            company: "",
            activeUser: "",
            selectedUser: "",
            savedSuccessfully: false,
            submitDisabled: true,
            margins: {
                top: 80,
                bottom: 60,
                left: 40,
                width: 522
            },
            pdfActive: false,

        }

    },
    watch: {
        user() {
            if (this.user) {
                this.activeUser = this.user
            }
        },
        activeUser(val) {
            this.savedSuccessfully = false;
            this.submitDisabled = false;
            this.id = val._id;
            this.employee = val.name;
            this.job = val.radnoMesto;
            this.boss = val.nadredjeni;
            if (val.pdpSchema) {
                this.capability = val.pdpSchema.capability;
                this.capabilityActions = val.pdpSchema.capabilityActions;
                this.capabilityMeasures = val.pdpSchema.capabilityMeasures;
                this.area = val.pdpSchema.area;
                this.areaActions = val.pdpSchema.areaActions;
                this.areaMeasures = val.pdpSchema.areaMeasures;
                this.category = val.pdpSchema.category;
                this.comment = val.pdpSchema.comment;
                this.date = val.pdpSchema.date;
                this.finalRating = val.pdpSchema.finalRating;
                this.clicked = this.finalRating;
                this.hr = val.pdpSchema.hr;
                this.period = val.pdpSchema.period;

            } else {
                this.capability = "";
                this.capabilityActions = "";
                this.capabilityMeasures = "";
                this.area = "";
                this.areaActions = "";
                this.areaMeasures = "";
                this.category = "";
                this.comment = "";
                this.date = "";
                this.finalRating = "";
                this.clicked = null;
                this.hr = "";
                this.period = "";
            }
        }




    },
    methods: {
        ...mapActions(['changePDP', 'getCollegues']),
        makePDF() {
            this.pdfActive = true;
            setTimeout(() => {
                const invoiceName = "plan_licnog_razvoja",
                    content = this.$refs.content;

                const doc = new jsPDF({
                    orientation: "portrait",
                    unit: "px",
                    format: "a4",
                    hotfixes: ["px_scaling"],
                });
                html2canvas(content, {
                    scale: 1,
                }).then((canvas) => {
                    const margin = 10;
                    const imgWidth = doc.internal.pageSize.getWidth() - 2 * margin;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    if (imgHeight > doc.internal.pageSize.getHeight() - 2 * margin) {
                        const scale = (doc.internal.pageSize.getHeight() - 2 * margin) / imgHeight;
                        doc.addImage(
                            canvas.toDataURL("image/png"),
                            "PNG",
                            margin,
                            margin,
                            imgWidth * scale,
                            doc.internal.pageSize.getHeight() - 2 * margin
                        );
                    } else {
                        doc.addImage(
                            canvas.toDataURL("image/png"),
                            "PNG",
                            margin,
                            margin,
                            imgWidth,
                            imgHeight
                        );
                    }
                    doc.save(invoiceName + ".pdf");
                });
                this.pdfActive = false;
            }, 1000);
        },
        async submit() {
            let data = {
                pdp: {
                    employee: this.employee,
                    job: this.job,
                    category: this.category,
                    period: this.period,
                    date: this.date,
                    capability: this.capability,
                    capabilityActions: this.capabilityActions,
                    capabilityMeasures: this.capabilityMeasures,
                    area: this.area,
                    areaActions: this.areaActions,
                    areaMeasures: this.areaMeasures,
                    finalRating: this.clicked,
                    comment: this.comment,
                    boss: this.boss,
                    hr: this.hr,
                },
                id: this.id

            }

            await this.changePDP(data);
            await this.getCollegues(this.user.type);
            this.savedSuccessfully = true;
            this.submitDisabled = true;
        },

    },
    async created() {


    },

}
</script>


<template>
    <div id="pdf" ref="content">
        <div class="bg-danger bg-gradient w-100 text-center fw-bold text-white fs-4 py-2">PLAN LIČNOG RAZVOJA</div>
        <div class="row">
            <div class="col-md-6 ps-3 pt-3">
                <div class="w-100">
                    <div class="col-12 row">
                        <div class="col-md-4">
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Ime zaposlenog:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Naziv posla:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Kategorija uloge:</p><br>
                        </div>
                        <div class="col-md-8">
                            <input v-model="employee" class="input-gray ms-2 mt-2 w-100" /><br>
                            <input v-model="job" class="input-gray ms-2 mt-1 w-100" /><br>
                            <input v-model="category" class="input-gray ms-2 mt-1 w-100" /><br>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 ps-5 pt-3">
                <div class="w-100">
                    <div class="col-12 row">
                        <div class="col-md-4">
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Period koji je posmatran:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Datum pregleda:</p><br>
                        </div>
                        <div class="col-md-8">
                            <input v-model="period" class="input-gray ms-2 mt-2 w-100" /><br>
                            <input v-model="date" class="input-gray ms-2 mt-1 w-100" /><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="mt-5 row">
            <div class="col-md-3 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Sposobnosti koje treba
                    razviti (u odnosu na rezultate kompetencija)</div>
                <textarea v-if="!pdfActive" v-model="capability" class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ capability }}</span></div>

            </div>
            <div class="col-md-6 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Postupci / Akcije koji će Vam pomoći da razvijete ovu oblast:</div>
                <textarea v-if="!pdfActive" v-model="capabilityActions"
                    class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ capabilityActions }}</span>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Mere: Kako ćete znati da je ovo urađeno!</div>
                <textarea v-if="!pdfActive" v-model="capabilityMeasures"
                    class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ capabilityMeasures }}</span>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Tehnička oblast koju treba razvijati (u odnosu na rezultate Ličnih KPI-jeva)</div>
                <textarea v-if="!pdfActive" v-model="area" class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ area }}</span></div>
            </div>
            <div class="col-md-6 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Postupci / Akcije koji će Vam pomoći da razvijete ovu oblast:</div>
                <textarea v-if="!pdfActive" v-model="areaActions" class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ areaActions }}</span></div>
            </div>
            <div class="col-md-3 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header">
                    Mere: Kako ćete znati da je ovo urađeno!</div>
                <textarea v-if="!pdfActive" v-model="areaMeasures" class="input-gray input-large w-100 px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ areaMeasures }}</span></div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 p-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header-bottom">
                    Konačna preporuka </div>
                <div v-for="(item, i) in ratingDiv" class="border" :key="i" @click="clicked = i">
                    <div :class="clicked == i ? `bg-${item.color}` : `text-${item.color}`"
                        class="d-flex bg-gradient div-rating p-1 text-center align-items-center justify-content-center fw-bold px-2">
                        {{
                            item.text
                        }}</div>
                </div>
            </div>
            <div :class="pdfActive ? ' pb-5' : ''" class="col-md-8 px-0 pt-0">
                <div
                    class="d-flex align-items-center justify-content-center bg-danger bg-gradient w-100 text-center text-white box-header-bottom">
                    Komentar sa preporukom - predlog za budućnost </div>
                <textarea v-if="!pdfActive" v-model="comment"
                    placeholder="Direktni pretpostavljeni unosi komentar sa preporukom za finalnu odluku ovde:"
                    class="input-gray input-large w-100 height-textarea px-3"> </textarea>
                <div class="input-gray input-large-text w-100 px-3" v-if="pdfActive"><span>{{ comment }}</span></div>
            </div>

        </div>


        <div class="row">
            <div class="col-md-6 ps-3 pt-3">
                <div class="w-100">
                    <div class="col-12 row">
                        <div class="col-md-4 pe-0 me-0">
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Ime zaposlenog:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Potpis zaposlenog:</p><br>
                        </div>
                        <div class="col-md-8">
                            <input v-model="employee" class="input-gray ms-2 mt-2 w-100" /><br>
                            <input class="input-gray ms-2 mt-1 w-100" /><br>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 ps-5 pt-3 pe-0">
                <div class="w-100">
                    <div class="col-12 row">
                        <div class="col-md-5 pe-0 me-0">
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Ime osobe iz ljudskih resursa:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Potpis osobe iz ljudskih resursa:</p><br>
                        </div>
                        <div class="col-md-7 pe-0">
                            <input v-model="hr" class="input-gray ms-2 mt-2 w-100" /><br>
                            <input class="input-gray ms-2 mt-1 w-100" /><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-md-6 ps-3 pt-3">
                <div class="w-100">
                    <div class="col-12 row">
                        <div class="col-md-4 pe-0 me-0">
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Ime pretpostavljenog:</p><br>
                            <p class="pt-2 fw-bold float-end p-0 m-0 text-nowrap">Potpis pretpostavljenog:</p><br>
                        </div>
                        <div class="col-md-8">
                            <input v-model="boss" class="input-gray ms-2 mt-2 w-100" /><br>
                            <input class="input-gray ms-2 mt-1 w-100" /><br>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
        <div class="row">
            <div v-if="savedSuccessfully" class="col-12 bg-success p-3 rounded mb-3">Promene su uspesno sačuvane.</div>
            <div class="col-12 d-flex justify-content-center"><button @click="makePDF()"
                    class="btn btn-danger bg-gradient mb-5 px-5 me-2">Snimi PDF</button><button v-if="canSave"
                    :disabled="submitDisabled" @click="submit()" class="btn btn-primary mb-5 px-5">SAČUVAJ</button></div>
        </div>
    </div>
</template>
<style scoped>
.input-gray {
    background-color: rgb(209, 207, 207);
    border: 0;
}

.input-large {
    height: 200px;
    border: 0.5px solid white;
    resize: none;
}

.input-large-text {
    height: 100%;
    border: 0.5px solid white;
    white-space: pre-wrap;
}

.box-header {
    height: 50px;
    border: 0.5px solid white;
    font-size: 12px;
}

.box-header-bottom {
    height: 50px;
    border: 0.5px solid white;
}

.div-rating {
    border: 0.5px solid white;
    height: 80px;
    cursor: pointer;
}

.clicked {
    border: 7px groove rgb(6, 238, 6);
}


.height-textarea {
    height: 86%;
}
</style>