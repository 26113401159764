<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
import PersonalDevelopmentPlan from '@/components/PersonalDevelopmentPlanComponent.vue';
export default {
  components: {
    PersonalDevelopmentPlan
  },
  computed: mapGetters(['user', 'users', 'usersSameCompany']),
  watch: {
    async podredjeniName(val) {
      this.podredjeni.forEach((el) => {
        if (val == el.name) {
          this.activePodredjeni = el;
        }
      })
      if(this.user.availableTest.pdpIzmena){
        this.refreshPodredjeni(false);
      }else if (this.user.availableTest.pdpViewAll){
        this.refreshPodredjeni(true);
      }
     

    }
  },
  data() {
    return {
      podredjeni: [],
      podredjeniName: '',
      activePodredjeni: null,
      canSave: false,
      userData: null,
      userPlan: false,
      hasPdpIzmena: false,
    }
  },
  methods: {
    ...mapActions(['getProfile', 'getCompanies', 'getCollegues']),
    async refreshPodredjeni(allView) {
      this.podredjeni = [];
      if(allView){
        await this.usersSameCompany.forEach((el) => {
          if(el.pdpSchema){
          this.podredjeni.push(el)   
        }     
      });
      console.log("TEST", this.podredjeni);
      console.log("TEST1", this.usersSameCompany);
      } else if (!allView){        
        await this.usersSameCompany.forEach((el) => {
        if (el.nadredjeni == this.user.name) {
          this.podredjeni.push(el)
        }
      });
      }
      
    }, 
    async changePlanToUser() {
      this.userPlan = true;
      this.canSave = false;
      this.activePodredjeni = this.user
    },
    async changePlanToPodredjeni() {
      this.userPlan = false;
      this.canSave = true;

      
    },
  },
  async created() {

    await this.getProfile();
    
    if (this.user.availableTest.pdpIzmena) {
      this.hasPdpIzmena = true;
      this.canSave = true;
      await this.getCollegues(this.user.type);
      this.refreshPodredjeni(false);
    } else if(this.user.availableTest.pdpView) {
      this.hasPdpIzmena = false;
      this.canSave = false;
      this.userPlan = true;
      this.activePodredjeni = this.user
    } else if(this.user.availableTest.pdpViewAll){
      this.hasPdpIzmena = false;
      this.canSave = false;
      await this.getCollegues(this.user.type);
      this.refreshPodredjeni(true);
    }




  },
}
</script>

<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
  <div>
    <div v-if="canSave" class="header">
      <h1>Plan ličnog razvoja</h1>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div v-if="!userPlan || canSave" class="form-group">
          <label for="collegue">Izaberite podredjenog: </label>
          <select name="collegue" id="collegue" v-model="podredjeniName" class="ms-3">
            <option v-for="podr in podredjeni" v-bind:key="podr.name">{{ podr.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
      <button v-if="!userPlan && hasPdpIzmena" @click="changePlanToUser()" class="btn btn-danger bg-gradient float-end">Pogledaj svoj Plan</button>
      <button v-if="userPlan && hasPdpIzmena" @click="changePlanToPodredjeni()" class="btn btn-danger bg-gradient float-end">Pogledaj Plan podredjenih</button>
    </div>
    </div>
    
  </div>
  <div class="tallest mt-5">

    <PersonalDevelopmentPlan :user="activePodredjeni" :canSave="canSave" />

  </div>

</template>


<style>
.tallest {
  min-height: 1000px;
}
</style>
